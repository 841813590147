import React from 'react'
import { Link } from 'gatsby'
import './styles.css'

const Button = (props) => {

  return (
    <Link className="button" to={props.link}>{props.text}</Link>
  )
}

export default Button
