import React from 'react'
import idx from 'idx'
import ProfileCard from '../ProfileCard'
import '../Detail/styles.css'
import './styles.css'

const Attendees = ({ attendees }) => {
  const baseURL = '//dev-skillsailors.pantheonsite.io'
  return (
    <div className="attendees__wrapper">
      <h2 className="detail__vertical-title">Who's coming</h2>
      <div className="attendees__list">
        {attendees.map(attendee => {
          const imgSrc = idx(
            attendee.relationships.field_user_profile,
            _ => _.relationships.field_user_profile_image.uri.url
          )
            ? baseURL +
              idx(
                attendee.relationships.field_user_profile,
                _ => _.relationships.field_user_profile_image.uri.url
              )
            : '/profile.svg'

          return (
            <ProfileCard
              id={attendee.drupal_internal__uid}
              name={attendee.name}
              image={imgSrc}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Attendees
