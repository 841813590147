import React from 'react';
import { Link } from 'gatsby';
import '../Attendees/styles.css'

const ProfileCard = ({ name, image, id, role }) => (
  <Link className="attendees__item" to={`/user/${id}`} key={id}>
    <img
      className="attendees__image"
      src={image}
      alt={name}
    />
    <div>
      <span>{name}</span>
      <div className="attendees__role">{role}</div>
    </div>
  </Link>
);

export default ProfileCard;
