import React from 'react'
import './styles.css'

const ImageGallery = ({images}) => {

  const baseURL = 'https://dev-skillsailors.pantheonsite.io'

  return (
    <div className="image-gallery">
      <div className="image-gallery__image-grid">
        {images.map((image, index) =>
        <div key={index}>
          <img alt={`gallery ${index}`} src={`https://res.cloudinary.com/dvjhq5dpg/image/fetch/c_fill,g_auto,h_590,w_592/v1546964241/${baseURL + image.uri.url}`} />
        </div>)}
      </div>
    </div>
  )
}

export default ImageGallery
