import React from 'react'
import { Meta } from 'react-head'

const Metatags = ({
  description,
  title,
  canonical,
  app_id,
  og_image,
  twitter_image,
  social_default = {
    og_default_image: '',
    twitter_card_type: 'summary',
    twitter_site: '@skillsailor',
  },
}) => {
  const defaultDescription = description || 'Kick off your next adventure'
  const defaultTitle = title || 'Skill Sailors'

  return (
    <React.Fragment>
      {/* Facebook */}
      <Meta name="og:locale" content="en_EN" />
      <Meta property="og:title" content={defaultTitle} />
      <Meta property="og:description" content={defaultDescription} />
      <Meta property="og:url" content={canonical} />
      <Meta property="og:site_name" content="Skill Sailors" />
      {og_image && <Meta property="og:image" content={og_image} />}
      {app_id && <Meta property="fb:app_id" content={app_id} />}
      {/* Twitter */}
      <Meta name="twitter:card" content={social_default.twitter_card_type} />
      <Meta name="twitter:description" content={defaultDescription} />
      <Meta name="twitter:title" content={defaultTitle} />
      <Meta name="twitter:site" content={social_default.twitter_site} />
      <Meta name="twitter:creator" content={social_default.twitter_site} />
      {twitter_image && <Meta name="twitter:image" content={twitter_image} />}
    </React.Fragment>
  )
}

export default Metatags
