import { graphql } from 'gatsby'
import React from 'react'
import idx from 'idx'
import { Link } from 'gatsby'
import Country from '../components/Country'
import Button from '../components/Button'
import Layout from '../components/Layout'
import ProfileCard from '../components/ProfileCard'
import ImageGallery from '../components/ImageGallery'
import Attendees from '../components/Attendees'
import Navigation from '../components/Navigation'
import kebabCase from 'lodash.kebabcase'
import '../components/Button/styles.css'
import '../components/Detail/styles.css'
import './styles/voyage.css'
import './styles/trip.css'
import Seo from '../components/Seo'
import Metatags from '../components/Metatags'
import { decode } from '../../utils/textToHtml'

const TripTemplate = ({ data, pageContext, location }) => {
  
  

  const {
    drupal_id,
    format_start_date,
    format_end_date,
    relationships,
    field_address_1,
    field_accommodation_info,
    field_coworking_info,
    field_activities_info,
    field_end_date,
    field_arrival_info,
    field_trip_introduction,
  } = data.nodeTrip

  const introduction = idx(field_trip_introduction, _ => _.value) || ''

  const cloudinaryThumb =
    'https://res.cloudinary.com/dvjhq5dpg/image/fetch/w_100,h_100,c_fill/v1546964241/https://dev-skillsailors.pantheonsite.io'

  const images = idx(relationships, _ => _.field_image)
  const attendees = idx(relationships, _ => _.field_accepted_users)
  const profile = idx(
    relationships,
    _ =>
      _.uid.relationships.field_user_profile.relationships
        .field_user_profile_image.uri.url
  )

  let tripCompleted = false
  const tripEndDate = new Date(field_end_date).getTime()
  if (Date.now() >= tripEndDate) tripCompleted = true
  const og_image = idx(images, _ => _[0].uri.url) || ''
  const baseURL = 'https://dev-skillsailors.pantheonsite.io'
  const voyageLink = `/voyage/${
    data.nodeVoyage.drupal_internal__nid
  }/${kebabCase(data.nodeVoyage.title)}`

  return (
    <Layout>
      <Seo title={`${field_address_1 && field_address_1.locality}`} />
      <Metatags
        title={`${field_address_1 && field_address_1.locality}`}
        description={decode(introduction)}
        og_image={`https://res.cloudinary.com/dvjhq5dpg/image/fetch/c_fill,h_680,w_1024/v1546964241/${baseURL}${og_image}`}
      />
      <div className="layout-narrow">
        <div className="layout-narrow__inner">
          <div>
            <div className="detail__vertical">
              <div className="detail__vertical-block">
                {data.nodeVoyage && (
                  <Link className="trip__subtitle" to={voyageLink}>
                    <img className="trip__subtitle-icon" src="/back.svg"/>
                    {data.nodeVoyage.title}
                  </Link>
                )}
                <h1>
                  {field_address_1 && `${field_address_1.locality}, `}
                  {field_address_1 && (
                    <Country countryCode={field_address_1.country_code} />
                  )}
                </h1>
                <div className="trip__captain-name">
                  <ProfileCard
                    id={relationships.uid.drupal_internal__uid}
                    name={relationships.uid.name}
                    image={`${cloudinaryThumb}${profile}`}
                  />
                </div>
              </div>
            </div>
            {!tripCompleted ? (
              <div className="trip__meta">
                <div className="trip__section trip__section-cost">
                  {data.nodeVoyage && (
                    <div className="trip__cost">
                      ${data.nodeVoyage.field_monthly_cost} per month
                    </div>
                  )}
                  <div className="trip__days">
                    <span>{format_start_date}</span>
                    <span> until </span>
                    <span className="detail__sub-end-date">
                      {format_end_date}
                    </span>
                  </div>
                </div>
                <div className="trip__section">
                  <div className="detail__space-top">
                    <Button
                      link={'/admin/join-trip?id=' + drupal_id}
                      text="Join Trip"
                    ></Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="trip__completed">
                <h2 className="trip__completed-text">Trip completed</h2>
                <img className="trip__completed-icon" alt="trip completed" src="/confetti.svg"/>
              </div>
            )}
              <div className="trip__body">
                <div
                  className="detail__vertical detail__vertical-block-sm"
                  dangerouslySetInnerHTML={{
                    __html: introduction,
                  }}
                ></div>
                <div>
                  {attendees && attendees.length > 0 && (
                    <Attendees attendees={attendees} />
                  )}
                </div>
                <div className="detail__item">
                  <h2 className="detail__vertical-title">Accomodation?</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: idx(field_accommodation_info, _ => _.value),
                    }}
                  ></div>
                {data.nodeTrip.relationships.field_accomodation_images && images.length >= 1 &&
                  <a className="layout-narrow__md" href="/">
                    <ImageGallery images={data.nodeTrip.relationships.field_accomodation_images}/>
                  </a>
                }
                </div>
                <div className="detail__item">
                  <h2 className="detail__vertical-title">Coworking?</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: idx(field_coworking_info, _ => _.value),
                    }}
                  ></div>
                {data.nodeTrip.relationships.field_coworking_images && images.length >= 1 &&
                  <a className="layout-narrow__md" href="/">
                    <ImageGallery images={data.nodeTrip.relationships.field_coworking_images} />
                  </a>
                }
                </div>
                <div className="detail__item">
                  <h2 className="detail__vertical-title">Now, some fun?</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: idx(field_activities_info, _ => _.value),
                    }}
                  ></div>
                {data.nodeTrip.relationships.field_activities_images && images.length >= 1 &&
                  <a className="layout-narrow__md" href="/">
                    <ImageGallery images={data.nodeTrip.relationships.field_activities_images} />
                  </a>
                }
                </div>
                <div className="detail__item">
                  <h2 className="detail__vertical-title">How do I arrive?</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: idx(field_arrival_info, _ => _.value),
                    }}
                  ></div>
                </div>
              </div>
          </div>
          {data.allNodeStory.edges[0] && (
            <div className="voyage__stories">
              <h2 className="voyage__label">ADVENTURE LOG</h2>
              <div>
                {data.allNodeStory.edges.map(story => {
                  return (
                    <Link
                      to={`/story/${
                        story.node.drupal_internal__nid
                      }/${kebabCase(story.node.title)}`}
                      className="voyage__items-wrapper"
                    >
                      <div className="voyage__item">
                        {story.node.relationships.field_image && (
                          <img
                            className="voyage__item-img"
                            alt={story.title}
                            src={
                              'https://dev-skillsailors.pantheonsite.io/' +
                              story.node.relationships.field_image[0].uri.url
                            }
                          />
                        )}
                        <div className="voyage__item-inner">
                          <div>
                            <h2 className="voyage__item-title">
                              {story.node.title}
                            </h2>
                            <div className="voyage__item-body">
                              {story.node.created}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )
                })}
              </div>
            </div>
          )}

          <div className="detail__navigation detail__item">
            <Navigation
              next={pageContext.next}
              previous={pageContext.previous}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TripTemplate

export const pageQuery = graphql`
  query($nid: Int!) {
    nodeVoyage(
      relationships: {
        field_voyage_trips: {
          elemMatch: { drupal_internal__nid: { eq: $nid } }
        }
      }
    ) {
      title
      drupal_internal__nid
      field_monthly_cost
    }
    nodeTrip(drupal_internal__nid: { eq: $nid }) {
      drupal_id
      title
      field_date
      format_start_date: field_date(formatString: "MMM D")
      field_end_date
      format_end_date: field_end_date(formatString: "MMM D YYYY")
      field_address_1 {
        country_code
        locality
      }
      field_accommodation_info {
        value
      }
      field_coworking_info {
        value
      }
      field_activities_info {
        value
      }
      field_arrival_info {
        value
      }
      field_trip_introduction {
        value
      }
      relationships {
        field_accomodation_images {
          uri {
            url
          }
        }
        field_coworking_images {
          uri {
            url
          }
        }
        field_activities_images {
          uri {
            url
          }
        }
        field_image {
          uri {
            url
          }
        }
        uid {
          name
          drupal_internal__uid
          relationships {
            field_user_profile {
              relationships {
                field_user_profile_image {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
        field_accepted_users {
          name
          drupal_internal__uid
          relationships {
            field_user_profile {
              relationships {
                field_user_profile_image {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    allNodeStory(
      filter: {
        relationships: {
          field_trip_reference: { drupal_internal__nid: { eq: $nid } }
        }
      }
    ) {
      edges {
        node {
          drupal_id
          title
          drupal_internal__nid
          created(formatString: "MMM DD YYYY")
          relationships {
            field_image {
              uri {
                url
              }
            }
            uid {
              name
            }
          }
        }
      }
    }
  }
`
